import { ref, computed } from "vue";
import http from "@/http";
import { defineStore } from "pinia";

import type {
  Account,
  Contact,
  Incident,
  Asset,
  InvoiceOrder,
  Agreement,
  Location,
  LocationType,
  Product,
  AgreementProduct,
  Message,
} from "@/api-types";

export const useMainStore = defineStore("main", () => {
  const contact = ref<Contact | null>(null);
  const account = ref<Account | null>(null);
  const accounts = ref<Account[]>([]);
  const contacts = ref<Contact[]>([]);
  const incidents = ref<Incident[]>([]);
  const assets = ref<Asset[]>([]);
  const invoices = ref<InvoiceOrder[]>([]);
  const agreements = ref<Agreement[]>([]);
  const locations = ref<Location[]>([]);
  const locationTypes = ref<LocationType[]>([]);
  const products = ref<Product[]>([]);
  const agreementProducts = ref<AgreementProduct[]>([]);
  const messages = ref<Message[]>([]);

  const loadingContact = ref(false);
  const loadingAccount = ref(false);
  const loadingAccounts = ref(false);
  const loadingContacts = ref(false);
  const loadingIncidents = ref(false);
  const loadingAssets = ref(false);
  const loadingInvoices = ref(false);
  const loadingAgreements = ref(false);
  const loadingLocations = ref(false);
  const loadingLocationTypes = ref(false);
  const loadingProducts = ref(false);
  const loadingAgreementProducts = ref(false);
  const loadingMessages = ref(false);

  const initialized = ref(false);

  const getLanguage = () => localStorage.getItem('language')
  const setLanguage = (iso: string) => localStorage.setItem('language', iso);
  
  const getAccount = async () => {
    loadingAccount.value = true;
    const { data } = await http.get("/account");
    account.value = data;
    loadingAccount.value = false;
  };

  const setAccount = (id: string) => {
    account.value = accounts.value.find((a) => a.id === id) || null;
  }

  const getAccounts = async () => {
    loadingAccounts.value = true;
    const { data } = await http.get("/accounts");
    accounts.value = data;
    loadingAccounts.value = false;
  };

  const getContact = async () => {
    loadingContact.value = true;
    const { data } = await http.get("/contact");
    contact.value = data;
    loadingContact.value = false;
  };

  const getContacts = async () => {
    if (!account.value?.id) return;
    loadingContacts.value = true;
    const { data } = await http.get("/contacts", { params: { accountId: account.value.id } });
    contacts.value = data;
    loadingContacts.value = false;
  };

  const getIncidents = async () => {
    if (!account.value?.id) return;
    loadingIncidents.value = true;
    const { data } = await http.get("/incidents", { params: { accountId: account.value.id } });
    incidents.value = data;
    loadingIncidents.value = false;
  };

  const getAssets = async () => {
    if (!account.value?.id) return;
    loadingAssets.value = true;
    const { data } = await http.get("/assets", { params: { accountId: account.value.id } });
    assets.value = data;
    loadingAssets.value = false;
  };

  const getLocations = async () => {
    if (!account.value?.id) return;
    loadingLocations.value = true;
    const { data } = await http.get("/locations", { params: { accountId: account.value.id } });
    locations.value = data;
    loadingLocations.value = false;
  };

  const getLocationTypes = async () => {
    if (locationTypes.value.length > 0) return; // TODO some sort of caching, to not fetch every time
    loadingLocationTypes.value = true;
    const { data } = await http.get("/locationtypes");
    locationTypes.value = data;
    loadingLocationTypes.value = false;
  };

  const getInvoices = async () => {
    if (!account.value?.id) return;
    loadingInvoices.value = true;
    const { data } = await http.get("/invoices", { params: { accountId: account.value.id } });
    invoices.value = data;
    loadingInvoices.value = false;
  };

  const getProducts = async () => {
    if (!account.value?.id) return;
    loadingProducts.value = true;
    const { data } = await http.get("/products", { params: { accountId: account.value.id } });
    products.value = data;
    loadingProducts.value = false;
  };

  const getAgreements = async () => {
    if (!account.value?.id) return;
    loadingAgreements.value = true;
    const { data } = await http.get("/agreements", { params: { accountId: account.value.id } });
    agreements.value = data;
    loadingAgreements.value = false;
  };

  const getAgreementProducts = async () => {
    if (!account.value?.id) return;
    loadingAgreementProducts.value = true;
    const { data } = await http.get("/agreementproducts", { params: { accountId: account.value.id } });
    agreementProducts.value = data;
    loadingAgreementProducts.value = false;
  };

  const initialize = async () => {

    await Promise.all([
      getContact(),
      getAccount(),
      getAccounts(),
    ]);

    initialized.value = true;
    getLocationTypes();
  };

  return {

    initialized,
    contact,
    account,
    accounts,
    contacts,
    incidents,
    assets,
    invoices,
    agreements,
    locations,
    locationTypes,
    products,
    agreementProducts,

    getLanguage,
    setLanguage,
    getAccount,
    setAccount,
    getAccounts,
    getContacts,
    getIncidents,
    getAssets,
    getInvoices,
    getAgreements,
    getLocations,
    getProducts,
    getAgreementProducts,

    loadingContact,
    loadingAccount,
    loadingAccounts,
    loadingContacts,
    loadingIncidents,
    loadingAssets,
    loadingInvoices,
    loadingAgreements,
    loadingLocations,
    loadingLocationTypes,
    loadingProducts,
    loadingAgreementProducts,

    initialize,
  };
});
