import { createI18n } from 'vue-i18n'
import { useMainStore } from "@/stores/main";

export default function useI18n() {
    const mainStore = useMainStore();

    return createI18n({
        legacy: false,
        locale: mainStore.getLanguage() ?? 'no',
        //fallbackLocale: 'en',
        messages: {

            en: {

                productNames: {
                    'Microsoft_365_Copilot': 'Copilot for Microsoft 365',
                    'Dynamics_365_Operations_Application_Partner_Sandbox': 'Dynamics 365 Operations Application Partner Sandbox',
                    'Dynamics_365_Sales_Field_Service_and_Customer_Service_Partner_Sandbox': 'Dynamics 365 Sales, Field Service and Customer Service Partner Sandbox',
                    'SPE_E5': 'Microsoft 365 E5',
                    'POWERAPPS_DEV': 'Microsoft Power Apps for Developer',
                    'VIVA': 'Microsoft Viva-series',
                    'POWERAPPS_PER_USER': 'Power Apps Premium',
                    'POWER_BI_PRO': 'Power BI Pro',
                    'Power_Pages_vTrial_for_Makers': 'Power Pages vTrial for Makers',
                    'PROJECTPREMIUM': '',//'Project-abonnement 5',
                    'STREAM': '',//'Prøveversjon av Microsoft Stream',
                    'VISIOCLIENT': '',//'Visio-abonnement 2',
                    'CPC_E_8C_32GB_512GB': 'Windows 365 Enterprise 8 vCPU, 32 GB, 512 GB'
                },

                assets: {
                    singular: 'asset', //'enhet',
                    noRegistered: 'No registered assets.', //'Ingen registrerte enheter.',
                    productImage: 'Product image', //'Produktbilde',
                    usedBy: 'Used by', //'Brukes av',
                    change: 'Change', //'Endre',
                    about: 'About this assets', //'Om denne enheten',
                    serial: 'Serial number', //'Serienr',
                    status: 'Service status', //'Servicestatus',
                    info: 'Product information', //'Produktinformasjon',
                    operationalStatus: 'Operational status', //'Operasjonell status',
                    purchaseDate: 'Purchase date', //'Kjøpsdato',
                    warranty: 'Warranty', //'Garanti',
                    lastSignin: 'Last signin', //'Sist innlogget',
                    intune: 'Connected to InTune', //'Koblet til InTune',
                    managed: 'Managed', //'Administrert',
                    azure: 'Registered in AD', //'Registrert i AD',
                    specs: 'Specification', //'Spesifikasjoner',
                    os: 'Operating system', //'Operativsystem',
                    ver: 'Version', //'Versjon',
                    manufacturer: 'Manufacturer', //'Produsent',
                    model: 'Model', //'Modell'
                },

                finance: {
                    name: 'Finance', //'Økonomi',
                    ivoiceHistory: 'Invoice history', //'Fakturahistorikk',
                    noIvoiceHistory: 'No invoice history.', //'Ingen fakturahistorikk funnet.',
                    referenceOrder: 'Reference {id}', //'Kreditnota {id}',
                    orderPaid: 'Paid {date}', //'Betalt {date}'
                },

                incidents: {
                    name: 'Help desk', //'Help desk',
                    singular: 'case', //'sak',
                    orderSupport: 'Order support', //'Bestill support',
                    reportDamage: 'Report damage', //'Meld skade',
                    purchase: 'Purchase of product, service or license', //'Kjøp av produkt, tjeneste eller lisens',
                    send: 'Send inquiry', //'Send henvendelse',
                    sent: 'Your inquiry was sent', //'Din henvendelse er sendt',
                    your: 'Your cases', //'Dine saker',
                    new: 'New case', //'Ny sak',
                    createNew: 'Create new case', //'Opprett ny sak',
                    selectCategory: 'Select category', //'Velg kategori',
                    searchOptional: 'Leave empty if you can\'t find this information', //'Finner du ikke denne informasjonen? La denne stå tom.',
                    search: 'Search for equipment, meeting rooms or services', //'Søk på utstyr, møterom eller tjeneste',
                    describeProblem: 'Describe problem', //'Beskriv problemet',
                    noRegistered: 'No cases found.', //'Ingen saker funnet.',
                    ticketNr: 'Ticket ID', //'Saksnr',
                    serviceAndSupport: 'Customer service and technical support', //'Kundeservice og teknisk support',
                    yourContact: 'Your Bravo contact (KAM)', //'Din Bravo kontakt (KAM)',
                    open: 'No open cases | {n} open case | {n} open cases', //'Ingen åpne saker | {n} åpen sak | {n} åpne saker',
                    history: 'Support history', //'Sakshistorikk',
                    noHistoryRegistered: 'No cases registered for this asset.', //'Ingen sakshistorikk for denne enheten.',
                    subject: 'Subject', //'Emnefelt',
                    createdOn: 'Created on', //'Meldt inn',
                    createdBy: 'Created by', //'Sendt inn av',
                    messageHistory: 'Message history', //'Meldingshistorikk',
                    noMessageHistory: 'No messages for this case.', //'Ingen meldinger i denne saken.',
                    answer: 'Write answer...', //'Skriv svar...'
                    cancel: 'Cancel case', //'Kanseller sak'
                    cancelSure: 'Are you sure?', //'Er du sikker?',
                    cancelWhy: 'Tell us why the case no longer should be handled', //'Fortell oss gjerne hvorfor saken ikke lenger skal behandles',
                    cancelConfirm: 'Confirm', //'Bekreft kansellering'
                },

                services: {
                    name: 'Services', //'Tjenester',
                    service: 'Service', //'Tjeneste',
                    overview: 'Overview of your services', //'Se oversikt over dine tjenester',
                },

                settings: {
                    name: 'Settings', //'Innstillinger',
                    text: 'Employee must receive all emails about invoices', //'Ansatt skal motta alle eposter om faktura'
                },

                agreements: {
                    name: 'Agreements', //'Avtaler',
                    singular: 'agreement', //'avtale',
                    your: 'Your agreements', //'Dine avtaler',
                    number: 'Number of agreements', //'Antall avtaler',
                    noRegistered: 'No registered agreements.', //'Ingen registrerte avtaler.',
                    overview: 'See an overview on your agreements.', //'Se oversikt over dine avtaler',
                },

                locations: {
                    type: 'Type', //'Type',
                    singular: 'meeting room', //'møterom',
                    meetingRoom: 'Meeting rooms', //'Møterom',
                    your: 'Your meeting rooms', //'Dine møterom',
                    overview: 'Overview of your meeting rooms', //'Se oversikt over møterom',
                    unregister: 'Disconnect from meeting room', //'Avregistrer for møterom',
                    new: 'Register new meeting room', //'Registrer nytt møterom',
                    noRegistered: 'No registered meeting rooms', //'Det er ikke registrert noen møterom',
                    equipmentCount: 'Equipment count', //'Antall utstyr',
                    size: 'Size', //'Størrelse',
                    serviceAgreement: 'Service agreement', //'Serviceavtale',
                },

                licenses: {
                    name: 'Licenses', //'Lisenser',
                    singular: 'license', //'lisens',
                    select: 'Select licenses', //'Velg lisenser',
                    info: 'License information', //'Lisensinformasjon',
                    edit: 'Edit licenses', //'Rediger lisens',
                    number: 'Number of licenses', //'Antall lisenser',
                    available: 'Available licenses', //'Ledige lisenser',
                    noRegisteredFor: 'No registered licenses for {name}', //'Ingen registrerte lisenser på {name}',
                    other: 'Other licenses', //'Andre lisenser',
                    noRegisteredOther: 'No other registered licenses.', //'Ingen andre registrerte lisenser.',
                    microsoft: 'Microsoft licenses', //'Microsoft lisenser',
                    noRegisteredMicrosoft: 'No registered Microsoft licenses.', //'Ingen registrerte Microsoft-lisenser.',
                },

                equipment: {
                    name: 'Equipment', //'Utstyr',
                    singular: 'equipment', //'utstyr',
                    equipmentName: 'Equipment name', //'Utstyrsnavn',
                    select: 'Select equipment', //'Velg utstyr',
                    noRegisteredFor: 'No equipment registered for {name}', //'Det er ikke registrert noe utstyr på {name}',
                    belongs: 'Belongs to', //'Tilhører'
                },

                employees: {
                    name: 'Employees', //'Ansatte',
                    singular: 'employee', //'ansatt',
                    unregisterUser: 'Unregister for user', //'Avregistrer for bruker',
                    register: 'Register new employee', //'Registrer ny ansatt',
                    info: 'Employee information', //'Ansattinformasjon',
                    your: 'Your employees', //'Dine ansatte',
                    noRegistered: 'No registered employees.', //'Ingen registrerte ansatte.',
                    unregister: 'Offboard employee', //'Avregistrer ansatt',
                    overview: 'View employee directory', //'Se oversikt over ansatte',
                },

                feedback: {
                    name: 'Feedback', //'Tilbakemelding',
                    give: 'Give feedback', //'Gi tilbakemelding',
                    question: 'What would you rate the overall user experience of the portal?', //'Hva synes du om brukeropplevelsen i Bravo portalen?',
                    noGood: 'Not good', //'Ikke så bra',
                    good: 'Very good ', //'Veldig bra',
                    getGood: 'What can we do to improve?', //'Hva kan vi gjøre bedre?',
                    placeholder: '', //'Vi ønsker ærlige og konstruktive tilbakemeldinger så vi kan gjøre brukeropplevelsen bedre for deg',
                    email: 'Leave you email here if we can contact you for more feedback', //'Legg igjen epost om du vil snakke med oss',
                    send: 'Send feedback', //'Send tilbakemelding',
                    thanks: 'We appreciate your feedback', //'Vi setter pris på din tilbakemelding',
                    outro: 'Your feedback is utilized in the ongoing development of the portal to ensure the best possible user experience for you', //'Din tilbakemelding brukes i videre utvikling av portalen, for å gjøre brukeropplevelsen best mulig for deg.'
                },

                files: {
                    add: 'Add files',
                    drop: 'Drop files here or click to open',
                    dropHere: 'Drop files here',
                    limit: 'jpeg or png, max 4 MB'
                },

                general: {
                    account: 'Account', //'Konto',
                    noAccount: 'Invalid user', //'Ugyldig bruker',
                    login: 'Log in', //'Login',
                    logout: 'Log out', //'Logg ut',
                    details: 'Details', //'Detaljer',
                    category: 'Category', //'Kategori',
                    inventory: 'Inventory', //'Inventar',
                    expires: 'Expires', //'Utløper',
                    expirationDate: 'Expiration date', //'Utløpsdato',
                    delete: 'Delete', //'Slett',
                    notImplemented: 'Not implemented', //'Ikke implementert',
                    notImplementedIncoming: 'This feature is not implemented yet. We are working on it.', //'Funksjonen er ikke implementert, men kommer.',
                    coming: 'Incoming!', //'Kommer!',
                    goBack: 'Go back', //'Gå tilbake',
                    mandatory: 'Mandatory', //'Obligatorisk',
                    notMandatory: 'Not mandatory', //'Ikke obligatorisk',
                    firstname: 'First name', //'Fornavn',
                    lastname: 'Last name', //'Etternavn',
                    email: 'Email', //'Epost',
                    phone: 'Phone', //'Telefonnummer',
                    location: 'Location', //'Lokasjon',
                    city: 'City', //'Sted',
                    department: 'Department', //'Avdeling',
                    nearestManager: 'Nearest manager', //'Nærmeste leder',
                    next: 'Next', //'Neste',
                    save: 'Save', //'Lagre',
                    search: 'Search', //'Søk',
                    name: 'Name', //'Navn',
                    job: 'Job title', //'Stilling',
                    status: 'Status', //'Status',
                    noStatus: 'No status', //'Ingen status',
                    complianceStatus: 'Compliance status', //'Sikkerhetsstatus',
                    compliant: 'Compliant', //'Compliant',
                    notCompliant: 'Not compliant', //'Not compliant',
                    unknown: 'Unknown', //'Ukjent',
                    date: 'Date', //'Dato',
                    order: 'Order', //'Ordre',
                    sum: 'Sum', //'Sum',
                    other: 'Other', //'Annet',
                    errorTryAgain: 'Something went wrong, please try again', //'Noe gikk feil. Prøv igjen',
                    title: 'Title', //'Tittel',
                    open: 'Open', //'Åpen',
                    //active: 'Active', //'Aktiv',
                    closed: 'Closed', //'Lukket',
                    cancelled: 'Cancelled', //'Kansellert',
                    //solved: 'Solved', //'Løst',
                    topic: 'Topic', //'Emne',
                    contact: 'Contact', //'Kontakt',
                    floor: 'Floor', //'Etasje',
                    bravoCustomerService: 'Bravo customer service', //'Bravo kundeservice',
                    send: 'Send', //'Send',
                    readMore: 'Read more', //'Les mer',
                    readLess: 'Read less', //'Les mindre',
                    filter: 'Filter', //'Filter',
                    reset: 'Reset', //'Nullstill',
                    applyFilter: 'Apply filter', //'Bruk filter',
                    sort: 'Sort', //'Sorter',
                    en: 'Engelsk',
                    no: 'Norsk',
                    download: 'Download'
                }
            },

            no: {

                productNames: {
                    'Microsoft_365_Copilot': 'Copilot for Microsoft 365',
                    'Dynamics_365_Operations_Application_Partner_Sandbox': 'Dynamics 365 Operations Application Partner Sandbox',
                    'Dynamics_365_Sales_Field_Service_and_Customer_Service_Partner_Sandbox': 'Dynamics 365 Sales, Field Service and Customer Service Partner Sandbox',
                    'SPE_E5': 'Microsoft 365 E5',
                    'POWERAPPS_DEV': 'Microsoft Power Apps for Developer',
                    'VIVA': 'Microsoft Viva-serien',
                    'POWERAPPS_PER_USER': 'Power Apps Premium',
                    'POWER_BI_PRO': 'Power BI Pro',
                    'Power_Pages_vTrial_for_Makers': 'Power Pages vTrial for Makers',
                    'PROJECTPREMIUM': 'Project-abonnement 5',
                    'STREAM': 'Prøveversjon av Microsoft Stream',
                    'VISIOCLIENT': 'Visio-abonnement 2',
                    'CPC_E_8C_32GB_512GB': 'Windows 365 Enterprise 8 vCPU, 32 GB, 512 GB'
                },

                assets: {
                    singular: 'enhet',
                    noRegistered: 'Ingen registrerte enheter.',
                    productImage: 'Produktbilde',
                    usedBy: 'Brukes av',
                    change: 'Endre',
                    about: 'Om denne enheten',
                    serial: 'Serienr',
                    status: 'Servicestatus',
                    info: 'Produktinformasjon',
                    operationalStatus: 'Operasjonell status',
                    purchaseDate: 'Kjøpsdato',
                    warranty: 'Garanti',
                    lastSignin: 'Sist innlogget',
                    intune: 'Koblet til InTune',
                    managed: 'Administrert',
                    azure: 'Registrert i AD',
                    specs: 'Spesifikasjoner',
                    os: 'Operativsystem',
                    ver: 'Versjon',
                    manufacturer: 'Produsent',
                    model: 'Modell'
                },

                finance: {
                    name: 'Økonomi',
                    ivoiceHistory: 'Fakturahistorikk',
                    noIvoiceHistory: 'Ingen fakturahistorikk funnet.',
                    referenceOrder: 'Kreditnota {id}',
                    orderPaid: 'Betalt {date}'
                },

                incidents: {
                    name: 'Help desk',
                    singular: 'sak',
                    orderSupport: 'Bestill support',
                    reportDamage: 'Meld skade',
                    purchase: 'Kjøp av produkt, tjeneste eller lisens',
                    send: 'Send henvendelse',
                    sent: 'Din henvendelse er sendt',
                    your: 'Dine saker',
                    new: 'Ny sak',
                    createNew: 'Opprett ny sak',
                    selectCategory: 'Velg kategori',
                    searchOptional: 'Finner du ikke denne informasjonen? La denne stå tom.',
                    search: 'Søk på utstyr, møterom eller tjeneste',
                    describeProblem: 'Beskriv problemet',
                    noRegistered: 'Ingen saker funnet.',
                    ticketNr: 'Saksnr',
                    serviceAndSupport: 'Kundeservice og teknisk support',
                    yourContact: 'Din Bravo kontakt (KAM)',
                    open: 'Ingen åpne saker | {n} åpen sak | {n} åpne saker',
                    history: 'Sakshistorikk',
                    noHistoryRegistered: 'Ingen sakshistorikk for denne enheten.',
                    subject: 'Emnefelt',
                    createdOn: 'Meldt inn',
                    createdBy: 'Sendt inn av',
                    messageHistory: 'Meldingshistorikk',
                    noMessageHistory: 'Ingen meldinger i denne saken.',
                    answer: 'Skriv svar...',
                    cancel: 'Kanseller sak',
                    cancelSure: 'Er du sikker?',
                    cancelWhy: 'Fortell oss gjerne hvorfor saken ikke lenger skal behandles',
                    cancelConfirm: 'Bekreft kansellering'
                },

                services: {
                    name: 'Tjenester',
                    service: 'Tjeneste',
                    overview: 'Se oversikt over dine tjenester',
                },

                settings: {
                    name: 'Innstillinger',
                    text: 'Ansatt skal motta alle eposter om faktura'
                },

                agreements: {
                    name: 'Avtaler',
                    singular: 'avtale',
                    your: 'Dine avtaler',
                    number: 'Antall avtaler',
                    noRegistered: 'Ingen registrerte avtaler.',
                    overview: 'Se oversikt over dine avtaler',
                },

                locations: {
                    type: 'Type',
                    singular: 'møterom',
                    meetingRoom: 'Møterom',
                    your: 'Dine møterom',
                    overview: 'Se oversikt over møterom',
                    unregister: 'Avregistrer for møterom',
                    new: 'Registrer nytt møterom',
                    noRegistered: 'Det er ikke registrert noen møterom',
                    equipmentCount: 'Antall utstyr',
                    size: 'Størrelse',
                    serviceAgreement: 'Serviceavtale',
                },

                licenses: {
                    name: 'Lisenser',
                    singular: 'lisens',
                    select: 'Velg lisenser',
                    info: 'Lisensinformasjon',
                    edit: 'Rediger lisens',
                    number: 'Antall lisenser',
                    available: 'Ledige lisenser',
                    noRegisteredFor: 'Ingen registrerte lisenser på {name}',
                    other: 'Andre lisenser',
                    noRegisteredOther: 'Ingen andre registrerte lisenser.',
                    microsoft: 'Microsoft lisenser',
                    noRegisteredMicrosoft: 'Ingen registrerte Microsoft-lisenser.',
                },

                equipment: {
                    name: 'Utstyr',
                    singular: 'utstyr',
                    equipmentName: 'Utstyrsnavn',
                    select: 'Velg utstyr',
                    noRegisteredFor: 'Det er ikke registrert noe utstyr på {name}',
                    belongs: 'Tilhørighet',
                },

                employees: {
                    name: 'Ansatte',
                    singular: 'ansatt',
                    unregisterUser: 'Avregistrer for bruker',
                    register: 'Registrer ny ansatt',
                    info: 'Ansattinformasjon',
                    your: 'Dine ansatte',
                    noRegistered: 'Ingen registrerte ansatte.',
                    unregister: 'Avregistrer ansatt',
                    overview: 'Se oversikt over ansatte',
                },

                feedback: {
                    name: 'Tilbakemelding',
                    give: 'Gi tilbakemelding',
                    question: 'Hva synes du om brukeropplevelsen i Bravo portalen?',
                    noGood: 'Ikke så bra',
                    good: 'Veldig bra',
                    getGood: 'Hva kan vi gjøre bedre?',
                    placeholder: 'Vi ønsker ærlige og konstruktive tilbakemeldinger så vi kan gjøre brukeropplevelsen bedre for deg',
                    email: 'Legg igjen epost om du vil snakke med oss',
                    send: 'Send tilbakemelding',
                    thanks: 'Vi setter pris på din tilbakemelding',
                    outro: 'Din tilbakemelding brukes i videre utvikling av portalen, for å gjøre brukeropplevelsen best mulig for deg.'
                },

                files: {
                    add: 'Vedlegg',
                    drop: 'Slipp filer her eller klikk for å velge',
                    dropHere: 'Slipp filene her',
                    limit: 'jpeg eller png, maks 4 MB'
                },

                general: {
                    account: 'Konto',
                    noAccount: 'Ugyldig bruker',
                    login: 'Login',
                    logout: 'Logg ut',
                    details: 'Detaljer',
                    category: 'Kategori',
                    inventory: 'Inventar',
                    expires: 'Utløper',
                    expirationDate: 'Utløpsdato',
                    delete: 'Slett',
                    notImplemented: 'Ikke implementert',
                    notImplementedIncoming: 'Funksjonen er ikke implementert, men kommer.',
                    coming: 'Kommer!',
                    goBack: 'Gå tilbake',
                    mandatory: 'Obligatorisk',
                    notMandatory: 'Ikke obligatorisk',
                    firstname: 'Fornavn',
                    lastname: 'Etternavn',
                    email: 'Epost',
                    phone: 'Telefonnummer',
                    location: 'Lokasjon',
                    city: 'Sted',
                    department: 'Avdeling',
                    nearestManager: 'Nærmeste leder',
                    next: 'Neste',
                    save: 'Lagre',
                    search: 'Søk',
                    name: 'Navn',
                    job: 'Stilling',
                    status: 'Status',
                    noStatus: 'Ingen status',
                    complianceStatus: 'Sikkerhetsstatus',
                    compliant: 'Compliant',
                    notCompliant: 'Not compliant',
                    unknown: 'Ukjent',
                    date: 'Dato',
                    order: 'Ordre',
                    sum: 'Sum',
                    other: 'Annet',
                    errorTryAgain: 'Noe gikk feil. Prøv igjen',
                    title: 'Tittel',
                    open: 'Åpen',
                    //active: 'Aktiv',
                    closed: 'Løst',
                    cancelled: 'Kansellert',
                    //solved: 'Lukket',
                    topic: 'Emne',
                    contact: 'Kontakt',
                    floor: 'Etasje',
                    bravoCustomerService: 'Bravo kundeservice',
                    send: 'Send',
                    readMore: 'Les mer',
                    readLess: 'Les mindre',
                    filter: 'Filter',
                    reset: 'Nullstill',
                    applyFilter: 'Bruk filter',
                    sort: 'Sorter',
                    en: 'English',
                    no: 'Norwegian',
                    download: 'Last ned'
                }
            }
        }
    })
}