import axios from "axios";

const http = axios.create({
  baseURL: import.meta.env.VITE_APIURL,
});

http.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

export default http;
