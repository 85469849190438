<script setup lang="ts">
import type { IconName } from "@/types";
import type { PropType } from "vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

defineProps({
  heading: {
    type: String,
    required: true,
  },
  icon: {
    type: String as PropType<IconName>,
    default: "warning",
  },
});

const emit = defineEmits(["close"]);

const target = ref(null);

onClickOutside(target, (event) => emit("close"));
</script>

<template>
  <div class="no-doc-scroll fixed inset-0 left-0 top-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="absolute w-[500px] rounded-2xl bg-white shadow-lg" ref="target">
      <!-- header -->
      <div class="flex items-center justify-between border-b border-stroke-10">
        <div class="flex items-center gap-4 px-6 py-4">
          <Icon :name="icon" size="medium" background="info" />
          <div class="font-medium">{{ heading }}</div>
        </div>
        <Button variant="secondary-minimal" size="small" @click="emit('close')" icon="close"></Button>
      </div>

      <!-- body -->
      <div class="p-12">
        <slot></slot>
      </div>

      <!-- footer -->
      <div class="px-12 pb-12" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

