<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import useNavbarItems from "@/composables/getNavbarItems";

defineProps({
  floating: Boolean,
});

const { getNavbarItems } = useNavbarItems();
const navbarItems = computed(() => getNavbarItems());

const route = useRoute();
const isRoute = (name: string) => {
  return route.path.startsWith(`/main/${name}`);
};

</script>

<template>
  <div :class="floating ? 'fixed bottom-0 left-0 right-0 p-8' : 'items-center'" class="w-full">
    <div :class="{ 'flex justify-end pb-6': floating }">
      <FeedbackForm :mobile="floating" />
    </div>
    <div :class="floating ? 'flex justify-center' : 'flex-1'">
      <nav :class="{ 'w-full': floating }">
        <ul class="nav-list" :class="{ 'justify-center floated': floating }">
          <li v-for="(item, index) in navbarItems" :class="{ active: isRoute(item.name) }">
            <RouterLink :to="{ name: item.name }">
              <span class="icon-container" v-if="floating">
                <Icon :name="item.icon" />
              </span>
              <span>{{ item.title }}</span>
            </RouterLink>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped>
.nav-list {
  @apply inline-grid select-none rounded-full bg-interface-40 w-full;
  grid-template-columns: repeat(v-bind(navbarItems.length), 1fr);
  grid-auto-flow: column;

  li {
    @apply rounded-full text-[#7486A4];
    border-radius: 100vw;

    &.active {
      @apply break-keep bg-blue-90 text-white;
    
      a::before {
        @apply bg-green-100 rounded-full;
        content: "";
        height: 10px;
        width: 10px;
        display: v-bind(floating ? 'none' : 'initial');
      }
    }

    &:not(.active):hover {
        @apply text-blue-50;
    }
    
    a {
      @apply relative z-10 flex items-center justify-center gap-2 whitespace-nowrap outline-none px-10 h-14;
    }

    &:not(.active) a:focus-visible {
      @apply text-black;
    }
  }

  &.floated {
    @apply flex flex-wrap px-12 py-4 gap-x-[46px] gap-y-[23px];

    li {
      @apply text-ink-subtle text-x-small;

      &.active {
        @apply bg-interface-40 text-ink-contrast;
      }
  
      a {
        @apply flex-col gap-1 p-0;
      }
    }
  }
}
</style>
