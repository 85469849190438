<script setup lang="ts">
import { reactive, type PropType } from "vue";
import { type IconName } from "@/types";
import { computed } from "vue";

type Variant = "cta" | "primary" | "secondary" | "secondary-minimal" | "secondary-solid";

type Size = "medium" | "large" | "small";

const props = defineProps({
  variant: {
    type: String as PropType<Variant>,
    default: "primary",
  },
  size: {
    type: String as PropType<Size>,
    default: "medium",
  },
  icon: {
    type: String as PropType<IconName>,
    required: false,
  },
  iconPos: {
    type: String as PropType<"left" | "right">,
    default: "left",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => ({
  "bg-interactive-primary hover:bg-interactive-primary-hover disabled:bg-interface-30 disabled:text-ink-disabled":
    props.variant === "cta",
  "bg-blue-90 hover:bg-blue-100 text-white disabled:bg-interface-30 disabled:text-ink-disabled":
    props.variant === "primary",
  "border border-ink-base hover:bg-interface-20 disabled:text-ink-disabled disabled:border-ink-disabled":
    props.variant === "secondary",
  "hover:bg-interface-20": 
    props.variant === "secondary-minimal",
  "bg-interface-40 hover:bg-light-neutral-60 disabled:bg-interface-30 disabled:border-ink-disabled":
    props.variant === "secondary-solid",
  "text-medium py-4 px-6": 
    props.size === "large",
  "text-small p-4": 
    props.size === "medium",
  "text-x-small py-3 px-4": 
    props.size === "small",
  disabled: props.disabled,
}));

const iconSize = computed(() => {
  if (props.size === "large") return "large";
  if (props.size === "medium") return "medium";
  return "small";
});
</script>

<template>
  <button class="whitespace-nowrap rounded-full font-normal relative leading-none" :class="classes" :disabled="disabled">
    <div class="absolute inset-0 flex items-center justify-center" v-if="loading">
      <DotsLoader />
    </div>
    <div class="inline-flex items-center gap-2 align-bottom" :class="{ 'invisible': loading }">
      <Icon v-if="icon && iconPos === 'left'" :name="icon" :size="iconSize" />
      <slot></slot>
      <Icon v-if="icon && iconPos === 'right'" :name="icon" :size="iconSize" />
    </div>
  </button>
</template>
