import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";
import LoginLayout from "../layouts/LoginLayout.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: LoginLayout,
      children: [
        {
          path: "",
          name: "login",
          component: () => import("../views/LoginView.vue"),
        },
      ],
    },
    {
      path: "/main",
      component: MainLayout,
      children: [
        {
          path: "",
          redirect: { name: "inventory" },
        },
        {
          path: "account",
          name: "account",
          component: () => import("../views/AccountView.vue"),
        },
        {
          path: "inventory",
          name: "inventory",
          component: () => import("../views/InventoryView.vue"),
        },
        {
          path: "inventory/assets",
          redirect: { name: "inventory" },
        },
        // TODO: confirm that this is not needed as assets are always connected to a location or employee
        // {
        //   path: "inventory/assets/:id",
        //   name: "asset",
        //   props: true,
        //   component: () => import("../views/AssetView.vue"),
        // },
        {
          path: "inventory/licenses",
          redirect: { name: "inventory" },
        },
        {
          path: "inventory/licenses/:id",
          name: "license",
          props: true,
          component: () => import("../views/LicenseView.vue"),
        },
        {
          path: "inventory/employees",
          name: "employees",
          component: () => import("../views/EmployeesView.vue"),
        },
        {
          path: "inventory/employees/register",
          name: "employee-register",
          component: () => import("../views/EmployeeRegisterView.vue"),
        },
        {
          path: "inventory/employees/:id",
          name: "employee",
          props: true,
          component: () => import("../views/EmployeeView.vue"),
        },
        {
          path: "inventory/employees/:id/assets",
          redirect: { name: "inventory/employees" },
        },
        {
          path: "inventory/employees/:id/assets/:assetId",
          name: "employee-asset",
          props: true,
          component: () => import("../views/EmployeeAssetView.vue"),
        },
        {
          path: "inventory/employees/:id/assets/:assetId/:incidentId",
          name: "employee-asset-incident",
          props: true,
          component: () => import("../views/EmployeeAssetIncidentView.vue"),
        },
        {
          path: "inventory/locations",
          name: "locations",
          component: () => import("../views/LocationsView.vue"),
        },
        {
          path: "inventory/locations/:id",
          name: "location",
          props: true,
          component: () => import("../views/LocationView.vue"),
        },
        {
          path: "inventory/locations/:id/assets/:assetId",
          name: "location-asset",
          props: true,
          component: () => import("../views/LocationAssetView.vue"),
        },
        {
          path: "inventory/locations/:id/assets/:assetId/:incidentId",
          name: "location-asset-incident",
          props: true,
          component: () => import("../views/LocationAssetIncidentView.vue"),
        },

        {
          path: "inventory/agreements/:id",
          name: "agreement",
          props: true,
          component: () => import("../views/AgreementView.vue"),
        },
        {
          path: "inventory/services",
          name: "services",
          component: () => import("../views/ServicesView.vue"),
        },
        {
          path: "inventory/services/:id",
          name: "service",
          props: true,
          component: () => import("../views/ServiceView.vue"),
        },
        {
          path: "finance",
          name: "finance",
          component: () => import("../views/FinanceView.vue"),
        },
        {
          path: "finance/agreements/:type?",
          name: "finance-agreements",
          props: true,
          component: () => import("../views/FinanceAgreementsView.vue"),
        },
        {
          path: "incidents",
          name: "incidents",
          component: () => import("../views/IncidentsView.vue"),
        },
        {
          path: "incidents/create",
          name: "incident-create",
          component: () => import("../views/IncidentCreateView.vue"),
        },
        {
          path: "incidents/:id",
          name: "incident",
          props: true,
          component: () => import("../views/IncidentView.vue"),
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("../views/SettingsView.vue"),
        },
      ],
    },
  ],
});

export default router;
