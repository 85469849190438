<script setup lang="ts" >
import { computed, ref } from 'vue';
import useNavbarItems from "@/composables/getNavbarItems";

defineProps({
  showHamburger: Boolean
});

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
  
const { getNavbarItems } = useNavbarItems();
const navbarItems = computed(() => getNavbarItems());

</script>

<template>
  <div class="flex select-none gap-4">
    <!-- blue bar -->
    <div class="grid h-24 flex-1 place-content-center rounded-full bg-blue-90">
      <img src="@/assets/logo.png" alt="Bravo logo" />
    </div>

    <!-- account icon -->
    <router-link :to="{ name: 'account' }">
    <div class="flex h-24 w-24 items-center justify-center rounded-full bg-interface-40">
        <Icon name="user" size="medium" />
      </div>
    </router-link>

    <!-- menu -->
     <div :class="[showHamburger ? '' : 'hidden', 'relative']">

       <Button class='w-24 h-24' variant="secondary-solid" icon="menu" @click.prevent="toggleMenu"></Button>

       <ul v-if="isMenuOpen" class="absolute bg-white rounded-3xl m-2 right-0 border border-light-neutral-40 overflow-hidden z-10">
        <li v-for="item in navbarItems" class="p-4 hover:bg-light-neutral-30">
        <RouterLink :to="{ name: item.name }">
          <span>{{ item.title }}</span>
        </RouterLink>
      </li>
       </ul>
    </div>
  </div>
</template>

<style scoped>

</style>