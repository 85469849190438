export type Role = 'Administrator' | 'Employee' | 'Unknown';

export type Feature =
  'employee-register' |
  'location-register' |
  'settings' |
  'case-cancel';

export interface AccessConfig {
  accountIds?: string[];
  contactIds?: string[];
  role?: Role;
  features: Feature[];
}

const testAccounts = [
  '5a85c9a9-272c-ec11-b6e5-000d3aba57f0', // Nyegaard Technology
  // 'f50d40ee-219b-ee11-be37-002248993053', // DEMO - Bravo Norge
  '98dc8fd7-641e-ef11-840a-002248a2d702', // DEMO - Bravo Norge Child
  '9dcf162d-aff0-e911-a811-000d3ab5d29e', // NOVA CONSULTING GROUP AS
  '41f8bd7c-cd77-ed11-81ab-002248993415', // NOVACARE AS
];

export const accessConfigs: AccessConfig[] = [
  {
    accountIds: [...testAccounts],
    features: [
      'employee-register',
      'location-register',
      'settings',
      'case-cancel'
    ]
  },
];