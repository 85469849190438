<script setup lang="ts">
import { useAuth } from "@/config/useAuth";
import { useMainStore } from "@/stores/main";
import { useRouter } from "vue-router";
import { samlInstance, loginState } from "@/config/mslaConfig";
import { onMounted } from "vue";

const { aquireToken, handleRedirect } = useAuth();

const mainStore = useMainStore();
const router = useRouter();

onMounted(async () => {
  await samlInstance.initialize();
  await handleRedirect();

  try {
    await aquireToken();
  } catch {
    console.log("Aquire token failed.");
    return;
  }

  try {
    await mainStore.initialize();
  } catch {
    router.push({ name: "login", query: loginState.isAuthenticated ? { error: "no-account" } : undefined });
    return;
  }

  if (location.pathname === "/") {
    router.push({ name: "inventory" });
  }

  setInterval(
    async () => {
      await aquireToken();
    },
    1000 * 60 * 5,
  );
});
</script>

<template>
  <router-view />
</template>

<style>
body {
  overflow-y: scroll;
}
</style>
