<script setup lang="ts">
import { useMainStore } from "@/stores/main";
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";

const mainStore = useMainStore();

const { width: windowWidth } = useWindowSize();
const floatNavbar = computed(() => windowWidth.value < 1160);

</script>

<template>
  <div class="p-8" v-if="mainStore.initialized">
    <TopBar :showHamburger="false" />
    <div class="mx-auto mt-6 max-w-screen-xl">
        <div class="flex items-center justify-between gap-2">
            <NavBar :floating="floatNavbar" />
            <div class="w-full md:w-fit">
                <LocationSelector />
            </div>
            <div>
                <LanguageSelector />
            </div>
        </div>
      <router-view></router-view>
    </div>
  </div>
  <Toast />
</template>
