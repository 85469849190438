import "./style.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createGtm } from '@gtm-support/vue-gtm';

import App from "./App.vue";
import router from "./router";
import useI18n from "./i18n"

const app = createApp(App);

app.use(router);

app.use(
  createGtm({
    id: "GTM-THCWVPLN",
    vueRouter: router
  }),
);

app.use(createPinia());
app.use(useI18n());

app.mount("#app");
