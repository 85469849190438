import { ref } from "vue";
import { samlInstance, loginState } from "./mslaConfig";

export function useAuth() {
  const isAuthenticated = ref(false);

  const login = async () => {
    try {
      if (!samlInstance) {
        throw new Error("MSAL instance not initialized");
      }
      const loginResponse = await samlInstance.loginRedirect();
      isAuthenticated.value = true;

      console.log("Login success", loginResponse);
    } catch (error) {
      console.log("Login error", error);
    }
  };

  const aquireToken = async () => {
    try {
      if (!samlInstance) {
        throw new Error("MSAL instance not initialized");
      }
      const tokenRequest = {
        account: loginState.account,
        scopes: ["api://4ece62f7-f3d4-4518-b173-0d12981a5281/data"],
      };
      // @ts-ignore
      const response = await samlInstance.acquireTokenSilent(tokenRequest);
      localStorage.setItem("token", response.accessToken);
    } catch (error) {
      console.log("Token error", error);
    }
  };

  const logout = async () => {
    if (!samlInstance) {
      throw new Error("MSAL instance not initialized");
    }

    localStorage.removeItem("token");
    samlInstance.logoutRedirect();
    isAuthenticated.value = false;
  };

  const handleRedirect = async () => {
    try {
      await samlInstance.handleRedirectPromise();
      const accounts = samlInstance.getAllAccounts();
      loginState.isAuthenticated = accounts.length > 0;
      loginState.account = accounts[0];
    } catch (error) {
      console.error("Redirect error", error);
    }
  };

  return { isAuthenticated, login, logout, aquireToken, handleRedirect };
}
