export default () => {

  const capitalizeEachWord = (input: string, wordSeparator: string, ignoreWords: string[] = []) =>
    input
      .split(wordSeparator)
      .map((word) => ignoreWords.some(w => w === word) ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(wordSeparator);

  return {
    capitalizeEachWord
  }

}