<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  light: Boolean,
});
const classes = computed(() => ({
  'bg-white': !props.light,
  'bg-black': props.light,
}));
</script>
<template>
  <div class="flex gap-1">
    <span :class="classes" class="circle animate-loader"></span>
    <span :class="classes" class="circle animate-loader animation-delay-200"></span>
    <span :class="classes" class="circle animate-loader animation-delay-400"></span>
  </div>
</template>

<style scoped>
.animation-delay-200 {
  animation-delay: 0.2s;
}

.animation-delay-400 {
  animation-delay: 0.4s;
}

.circle {
  @apply w-2 h-2 my-2 rounded-full;
}
</style>