<script setup lang="ts">
import { inject, watch } from "vue";
import type { FormFieldState } from "@/types";
import { useTextareaAutosize } from "@vueuse/core";

const model = defineModel({ default: "" });

defineProps({
  maxchars: {
    type: Number,
    default: 0,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const state = inject("state") as FormFieldState;
const { textarea, input } = useTextareaAutosize()

watch(() => model.value, () => {
  input.value = model.value;
}, { immediate: true });

</script>

<template>
  <div>
    <textarea ref="textarea" class="min-h-36 rounded-2xl py-4 textarea" v-model="input" @input="(e) => model = (e.target as HTMLTextAreaElement).value" :disabled="state === 'disabled'" :placeholder="placeholder"></textarea>
    <div v-if="maxchars > 0" class="text-small pt-1 pl-3" :class="{ 'text-ink-subtle': maxchars === 0 || input.length <= maxchars, 'text-danger-100': input.length > maxchars }">
      {{ input.length }} / {{ maxchars }}
    </div>
  </div>
</template>

<style scoped>
textarea {
  resize: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

textarea::-webkit-scrollbar {
  display: none;
}
</style>
