<script setup lang="ts">
import useToast from '@/composables/useToast';
const { toasts } = useToast();
</script>

<template>
  <TransitionGroup name="toast" tag="ul" class="fixed right-8 top-8 flex flex-col gap-4">
    <li v-for="(toast, index) in toasts" :key="index">
      <div class="rounded-lg flex items-center" :class="{
      'bg-success-10 text-success-100': toast.type === 'positive',
      'bg-danger-10 text-danger-100': toast.type === 'negative',
      'bg-info-10 text-info-100': toast.type === 'neutral',
      'bg-info-100 text-info-10': toast.type === 'default',
    }">
        <div class="p-4">{{ toast.message }}</div>
      </div>
    </li>
  </TransitionGroup>
</template>

<style scoped>
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}

.toast-enter-to,
.toast-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease;
}
</style>