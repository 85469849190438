import type { IconName } from "@/types";
import { useAccess } from "@/config/useAccess";
import { useI18n } from "vue-i18n";

export default () => {

    const { t } = useI18n();

    const getNavbarItems = (): { icon: IconName, name: string, title: string }[] => {

        const arr = [
            { icon: "package" as IconName, name: "inventory", title: t("general.inventory") },
            { icon: "invoice" as IconName, name: "finance", title: t("finance.name") },
            { icon: "users" as IconName, name: "incidents", title: t("incidents.name") }
        ];

        if (useAccess().hasAccess("settings")) {
            arr.push({ icon: "gear" as IconName, name: "settings", title: t("settings.name") });
        };

        return arr;
    }

    return {
        getNavbarItems
    }
}