<script setup lang="ts">
import type { FormFieldState } from "@/types";
import { computed, ref } from "vue";
import { inject, type PropType } from "vue";
import { onClickOutside } from "@vueuse/core";

const model = defineModel();

const state = inject('state') as FormFieldState;

const props = defineProps({
    items: {
        type: Array as PropType<any[]>,
        required: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    labelField: {
        type: String,
        default: 'label'
    },
    valueField: {
        type: String,
        default: 'value'
    },
    dropAutoWidth: {
        type: Boolean,
        default: false
    },
    dropRightAlign: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);
const target = ref(null)
const toggle = ref<HTMLButtonElement | null>(null)

const selectedItem = computed(() => props.items.find(item => item[props.valueField] === model.value));

const onSelect = (item: any) => {
    emit('update:modelValue', item[props.valueField]);
    isOpen.value = false;
    toggle.value?.focus();
}

onClickOutside(target, event => isOpen.value = false)

</script>

<template>
    <div class="relative" ref="target">
        <button class="w-full" ref="toggle" @click.prevent="isOpen = !isOpen" :disabled="state === 'disabled'">
            <slot name="toggle" :item="selectedItem">
                <div class="toggle">
                    {{ selectedItem ? selectedItem[labelField] : placeholder }}
                </div>
            </slot>
        </button>
        <Transition>
            <div v-if="isOpen" :class="{ 'w-full': !dropAutoWidth, 'right-0': dropRightAlign }" class="mt-5 absolute z-10 rounded-2xl bg-white border border-stroke-10 max-h-80 overflow-y-auto">
                <ul>
                    <li v-for="(item, index) in items" :key="index">
                        <slot name="item" :item="item" :select="onSelect">
                            <button class="item w-full" @click.prevent="onSelect(item)">
                                {{ item[labelField] }}
                            </button>
                        </slot>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.toggle {
    @apply h-16 w-full flex items-center rounded-2xl border border-stroke-30 bg-interface-background-20 pl-6 pr-12 whitespace-nowrap text-ink-subtle bg-no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNSI+PHBhdGggZD0iTTE3LjAzMDYgMTYuNTg3N2EuNzUwOC43NTA4IDAgMCAxIC4yMi41MzA2Ljc1MDEuNzUwMSAwIDAgMS0uMjIuNTMwN2wtNC41IDQuNWEuNzQ5NC43NDk0IDAgMCAxLS41MzA2LjIxOTkuNzQ4Ni43NDg2IDAgMCAxLS4yODcyLS4wNTcyLjc0ODQuNzQ4NCAwIDAgMS0uMjQzNC0uMTYyN2wtNC41LTQuNWEuNzUwOC43NTA4IDAgMCAxIDAtMS4wNjEzLjc1MDYuNzUwNiAwIDAgMSAxLjA2MTIgMEwxMiAyMC41NThsMy45Njk0LTMuOTcwM2EuNzQ4Ni43NDg2IDAgMCAxIC41MzA2LS4yMi43NTA5Ljc1MDkgMCAwIDEgLjUzMDYuMjJabS05LTcuOTM4OEwxMiA0LjY3ODZsMy45Njk0IDMuOTcwM2EuNzUwMy43NTAzIDAgMSAwIDEuMDYxMi0xLjA2MTJsLTQuNS00LjVhLjc0OTguNzQ5OCAwIDAgMC0xLjA2MTIgMGwtNC41IDQuNWEuNzUwNC43NTA0IDAgMSAwIDEuMDYxMiAxLjA2MTJaIiBmaWxsPSIjMUIxQzFEIi8+PC9zdmc+");
    background-position: right 1rem center;
    background-size: 1.5rem;
}

button:has(.toggle):focus-visible {
    @apply outline-none;

    .toggle {
        @apply border border-blue-50 bg-interface-20 outline-none;
    }
}

.item {
    @apply flex items-center p-4;

    &:hover,
    &:focus-visible {
        @apply bg-interface-20 outline-none;
    }
}
</style>