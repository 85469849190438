<script setup lang="ts">
import type { FormFieldState, IconName } from "@/types";
import { inject, type PropType } from "vue";

const state = inject("state") as FormFieldState;

const model = defineModel();

defineProps({
  icon: {
    type: String as PropType<IconName>,
  },
  placeholder: {
    type: String,
    required: false,
  },
});
</script>

<template>
  <div class="relative flex items-center">
    <Icon v-if="icon" :name="icon" class="absolute ml-2 scale-[62.5%] transform" />
    <input
      type="text"
      :placeholder="placeholder"
      class="h-16"
      :class="{ '!pl-12': !!icon }"
      :disabled="state === 'disabled'"
      v-model="model" />
  </div>
</template>
