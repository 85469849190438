<script setup lang="ts">
import type { FormFieldState } from "@/types";
import { provide, type PropType } from "vue";

const props = defineProps({
  state: {
    type: String as PropType<FormFieldState>,
    default: "base",
  },
  label: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  helpText: {
    type: String,
    default: "",
  },
});

provide("state", props.state);
</script>

<template>
  <div class="flex flex-col gap-2" :class="state">
    <label v-if="label" class="text-display-small font-medium">{{ label }}</label>
    <div v-if="description">{{ description }}</div>
    <div>
      <slot></slot>
    </div>
    <div class="help-text text-small text-ink-subtle" v-if="helpText">{{ helpText }}</div>
  </div>
</template>

<style scoped>
:deep(input),
:deep(select),
:deep(textarea) {
  @apply w-full rounded-2xl border border-stroke-30 bg-interface-background-20 px-4 text-ink-contrast;

  &:focus-visible {
    @apply border-blue-50 bg-interface-20 outline-none;

    &::placeholder {
      @apply text-transparent;
    }
  }

  &::placeholder {
    @apply text-ink-subtle;
  }
}

.disabled {

  label,
  .help-text {
    @apply text-ink-disabled;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply border-stroke-20;
  }
}

.valid {
  .help-text {
    @apply text-success-100;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply border-success-100;
  }
}

.invalid {
  .help-text {
    @apply text-danger-100;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply border-danger-100;
  }
}
</style>
