<script setup lang="ts">
import type { FeedbackModel } from '@/api-types';
import http from '@/http';
import { useConfirmDialog } from '@vueuse/core';
import type { AxiosResponse } from 'axios';
import { ref, reactive } from 'vue'; // Import the reactive function from the vue package
const { isRevealed, reveal, cancel } = useConfirmDialog();

defineProps({
  mobile: Boolean,
});

const feedbackModel = reactive<FeedbackModel>({
  rating: -1,
  text: '',
  createdOn: '',
});

const hoverRating = ref<number | null>(null);

let displayThankYouMessage = false;

const postFeedback = async (feedback: FeedbackModel) => {
  feedback.createdOn = new Date().toISOString();
  feedback.url = window.location.href;
  const response = await http.post<FeedbackModel, AxiosResponse>('/feedbacks', feedback);
  if (response.status >= 200 && response.status < 300) {
    feedbackModel.rating = -1;
    feedbackModel.text = '';
    feedbackModel.createdOn = '';
    feedbackModel.contactEmail = '';
    displayThankYouMessage = true;
  }
};

const handleClose = () => {
  displayThankYouMessage = false;
  cancel();
};
</script>

<template>
  <Button class="z-10" :class="{'!fixed right-5 bottom-5': !mobile }" :variant="mobile ? 'primary' : 'secondary-solid'" icon="chat" @click.prevent="reveal">
    <span class="hidden sm:inline">{{ $t('feedback.name') }}</span>
  </Button>

  <teleport to="body">
    <Transition>
      <Dialog v-if="isRevealed" :heading="$t('feedback.give')" @close="handleClose" icon="chat">
        <div v-if="!displayThankYouMessage">
          <p class="font-medium">
            {{ $t('feedback.question') }}
          </p>
          <div class="flex flex-row text-light-neutral-50 mt-4">
            <Icon v-for="(i, index) in 5" name="star" size="huge" :id="index" @click="feedbackModel.rating = index" @mouseenter="hoverRating = index" @mouseleave="hoverRating = null"
              :class="index <= (hoverRating ?? feedbackModel.rating!) ? 'text-green-90' : ''" />
          </div>
          <div class="flex justify-between text-x-small">
            <span>{{ $t('feedback.noGood') }}</span>
            <span>{{ $t('feedback.good') }}</span>
          </div>
          <FormField v-if="feedbackModel.rating! > -1">
            <div class="mt-14">
              <p class="font-medium">
                {{ $t('feedback.getGood') }}
              </p>
              <TextArea class="mt-4" v-model="feedbackModel.text" :placeholder="$t('feedback.placeholder')" />
            </div>
            <div class="mt-8">
              <p class="font-medium">
                {{ $t('feedback.email') }}
              </p>
              <Input class="mt-4" v-model="feedbackModel.contactEmail" :placeholder="$t('general.email')" />
            </div>
            <div class="flex justify-end mt-10">
              <Button variant="primary" @click.prevent="postFeedback(feedbackModel)">{{ $t('feedback.send') }}</Button>
            </div>
          </FormField>
        </div>

        <div v-if="displayThankYouMessage">
          <div class="flex flex-col justify-center items-center text-center gap-4">
            <Icon name="sparkle" size="huge"  background="success" class="text-success-100" />
            <Heading tag="h4" size="medium"> {{ $t('feedback.thanks') }} </Heading>
            <p class="text-small"> {{ $t('feedback.outro') }} </p>
          </div>
          
        </div>
      </Dialog>
    </Transition>
  </teleport>
</template>