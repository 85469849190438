import { useMainStore } from "@/stores/main";
import { accessConfigs, type Feature } from "@/config/accessConfig";

export function useAccess() {

    const mainStore = useMainStore();

    const hasAccess = (feature: Feature) => {

        const role = mainStore.contact?.portalRoleDescription;
        const config = accessConfigs.find(c => c.features.includes(feature));

        const accountId = mainStore.account?.id?.toLowerCase();
        const contactId = mainStore.contact?.id?.toLowerCase();

        if (!config) return false;
        if (config?.role && config.role !== role) return false;
        if (config.accountIds && accountId && !config.accountIds.includes(accountId)) return false;
        if (config.contactIds && contactId && !config.contactIds.includes(contactId)) return false;

        return true;
    }

    return {
        hasAccess
    }

}