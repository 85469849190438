import { ref } from "vue";

type ToastType = 'positive' | 'negative' | 'neutral' | 'default';

interface Toast {
  message: string;
  type: ToastType;
}

const toasts = ref<Toast[]>([]);

export default () => {

  const addToast = (message: string, type: ToastType = "default", timeout: number = 5000) => {

    const toast: Toast = {
      message,
      type
    }

    toasts.value.push(toast);

    setTimeout(() => {
      toasts.value.shift();
    }, timeout);
  }

  const dismissToast = (index: number) => {
    toasts.value.splice(index, 1);
  }

  return {
    toasts,
    addToast,
    dismissToast
  }

}